import Layout from "../../components/layouts/layout";
import CircleLoader from "../../components/Loaders/CircleLoader";
import {Breadcrumb, Button, Col, Form} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {SelectPicker, TreePicker} from "rsuite";
import {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useComponentCategories, useIdentifiers, useLang, useTermList} from "../../hooks";
import {yupResolver} from "@hookform/resolvers/yup";
import {ComponentInputSchema} from "./schema";
import useComponent from "../../hooks/useComponent";
import {InputValidationError} from "../../libs/error";
import {getUrlFromStoragePath} from "../../configs";
import {BackButton} from "../../components/BackButton/BackButton";
import {ImageInput} from "../../components/MediaInputs/imageInput";
import {useNotification} from "../../hooks/useNotification";
import {TextEdit} from "../../components";
import {useMediaSelector} from "../../components/MediaSelectorModal";
import MediaCardList from "../../components/MediaSelectorModal/MediaCardList";
import MediaModal from "../../components/MediaSelectorModal/MediaModal";
import ComponentSelectorModal from "../../components/ComponentSelectorModal";
import LayoutCard from "../../components/ComponentSelectorModal/LayoutCard";
import {LayoutInput} from "../../components/MediaInputs/LayoutInput";
import {usePageList} from "../../hooks/usePageList";
import {organizeData} from "../../helpers";

export function ComponentEditPage() {
  const {t} = useLang();
  const {id} = useParams();

  const {notifyError, notifySuccess} = useNotification();
  const {component, isLoading, editComponent, handleMediaRemove} = useComponent(id);

  const {
    register,
    handleSubmit,
    control,
    formState: {errors},
    setError,
    resetField,
    watch,
    reset,
    setValue
  } = useForm({resolver: yupResolver(ComponentInputSchema(t))});
  const {
    selectedMedia,
    setSelectedMedia,
    checkValue,
    setCheckValue,
    allMediaId,
    setAllMediaId
  } = useMediaSelector();
  const {
    pageList,
    isLoading: pageLoading,
    error: pageErr
  } = usePageList(null, {page: 1, limit: 100});

  const typeId = watch("type_id");
  const {identifiers, isLoading: isIdentifiersLoading, idErr} = useIdentifiers(typeId ? Number(typeId) : undefined);

  const {
    termList,
    error: termsError,
    isLoading: isTermsLoading,
  } = useTermList(10, {limit: 1000, page: 1});

  if (idErr) {
    notifyError(idErr, "/error");
  }
  const handleEdit = async (data) => {
    try {
      let componentData = {...data, media_id: checkValue, layout_pack_id: selectedLayout?.id}
      await editComponent(componentData);
      notifySuccess({
        title: `Component "${component.title}" updated successfully`
      }, "/components/manage");
    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Invalid input data!",
          message: err.message
        });
        return;
      }
      notifyError({
        title: "Failed to update component!",
        message: err.message
      });
    }
  };

  let componentName = watch("title");

  const handleServerRemove = async (media_id) => {
    try {
      await handleMediaRemove(media_id);
      notifySuccess({
        title: `Media Removed successfully`
      });
      setCheckValue(checkValue.filter(m => m!== media_id))
      setSelectedMedia(selectedMedia.filter(m => m.id !== media_id))
      setAllMediaId(allMediaId.filter(m => m!==media_id));
    } catch (err) {
      notifyError({
        title: "Failed to Removed media!",
        message: err.message
      });
    }
  };

  const [selectedLayout, setSelectedLayout] = useState(null);
  const handleRemoveLayout = (event, media) => {
    event.stopPropagation();
    setSelectedLayout(null);
  }

  useEffect(() => {
    if (component) {
      const {image1, image2, image3, ...prev}  = component
      reset(prev)

      let mediaIds = component?.files.map(m => m.media_id);
      let media = component?.files.map(m => m.media_content);

      setSelectedMedia(media);
      setCheckValue(mediaIds);
      setAllMediaId(mediaIds);

      setSelectedLayout(component?.layout_pack_details);
    }
  }, [component]);
  let type_id = Number(watch('type_id'))

  return (
    <>
      <Layout>
        {isLoading && <CircleLoader />}
        <header>
          <div className="d-flex justify-content-between align-items-center">
            <Breadcrumb className="d-flex align-items-center">
              <li className="breadcrumb-item">
                <Link to={`/components/manage`}> {t("components_manage_newComponent")}</Link>
              </li>
              <Breadcrumb.Item active>
                {componentName} {t("components_edit_editComponent")}
              </Breadcrumb.Item>
            </Breadcrumb>
            <BackButton path={-1} text={t("users_page_title")} />
          </div>
        </header>
        <section id="#taxonomy-create">
          <h2>{t("components_edit_editComponent")}/ {componentName}</h2>
        </section>
        <Form onSubmit={handleSubmit(handleEdit)} className="container-fluid mt-4">
          <div className="form-row gap-3 gap-md-0">
            <Form.Group as={Col} controlId="type_id">
              <Form.Label
                className="mark-required">{t("components_create_selectComponentType")}</Form.Label>
              <Controller render={({field}) => <TreePicker
                loading={isTermsLoading} {...field}
                data={organizeData(termList.terms)}
                onChange={(value) => {
                  field.onChange(value);
                  resetField("identifier_id");
                }}
                block
                searchable={false}
                placeholder="Select Categories"
                defaultExpandAll
              />
              } name="type_id" control={control}/>
              {errors.type_id?.message && (
                <Form.Text className="text-danger">{errors.type_id.message}</Form.Text>)}
            </Form.Group>
            <Form.Group as={Col} controlId="identifier_id" className={`${type_id === 12 ? 'd-none' : ''}`}>
              <Form.Label
                className="mark-required">{t("components_create_selectIdentifier")}</Form.Label>
              <Controller name="identifier_id" control={control}
                          render={({field: {onChange, value}}) => (
                            <SelectPicker value={value} onChange={onChange} data={identifiers}
                                          block loading={isIdentifiersLoading} disabled={type_id === 12} />
                          )} />
              {errors.identifier_id?.message && (
                <Form.Text className="text-danger">{errors.identifier_id.message}</Form.Text>)}
            </Form.Group>
          </div>
          <Form.Group controlId="page_id">
            <Form.Label>{t("menu_content_page")}</Form.Label>
            <Controller control={control} render={
              ({field}) =>
                <SelectPicker onChange={field.onChange} value={field.value} data={pageList?.pages.map(p => ({
                  label: p.title,
                  value: p.id
                }))} style={{width: "100%"}} />
            } name="page_id" />

            {errors?.page_id?.message && (
              <Form.Text className="text-danger">{errors.page_id.message}</Form.Text>
            )}
          </Form.Group>
          <div className="form-row gap-2 gap-md-0">
            <Form.Group as={Col} md={12} className="flex-grow-1" controlId="title">
              <Form.Label className="mark-required">{t("components_create_title")}</Form.Label>
              <Form.Control {...register("title")} placeholder={t("components_create_title_plhd")}
                            type="text" />
              {errors.title?.message && (
                <Form.Text className="text-danger">{errors.title.message}</Form.Text>)}
            </Form.Group>
            {/*<Form.Group as={Col} md={6} className="flex-grow-1" controlId="component_tag">*/}
            {/*  <Form.Label className="mark-required">{t("designIdentifier")}</Form.Label>*/}
            {/*  <Form.Control {...register("component_tag")} placeholder={t("tag_phld")}*/}
            {/*                type="text" />*/}
            {/*  {errors.component_tag?.message && (*/}
            {/*    <Form.Text className="text-danger">{errors.component_tag.message}</Form.Text>)}*/}
            {/*</Form.Group>*/}
          </div>
          <div className="form-row gap-2 gap-md-0">
            <Form.Group as={Col} controlId="layout_pack_id">
              <Form.Label
                className="mark-required">{t("components_create_selectLayout")}</Form.Label>
              <Controller
                control={control}
                name="layout_pack_id"
                render={({field}) => (
                  <ComponentSelectorModal isSingleMode selectedMedia={selectedLayout}
                                          setSelectedMedia={setSelectedLayout} type={type_id}>
                    {
                      selectedLayout ? (
                        <LayoutCard media={selectedLayout} isDelete handleRemove={handleRemoveLayout} />
                      ) : (
                        <div style={{height: "300px", width: "100%"}}>
                          <LayoutInput
                            errorMessage={type_id ? null : "Select a Component First."}
                            onChange={field.onChange}
                            onError={() => {
                              setError("image", {message: "Invalid image file!"});
                            }}
                          />
                        </div>
                      )
                    }
                  </ComponentSelectorModal>
                )
                } />
              {errors.layout_pack_id?.message && (
                <Form.Text className="text-danger">{errors.layout_pack_id.message}</Form.Text>)}
            </Form.Group>
          </div>
          <Form.Group className="flex-grow-1" controlId="name">
            <Form.Label>{t("subtitle")}</Form.Label>
            <Form.Control {...register("subTitle")} placeholder={t("subtitle_phld")}
                          type="text" />
            {errors.subTitle?.message && (
              <Form.Text className="text-danger">{errors.subTitle.message}</Form.Text>)}
          </Form.Group>
          <div className="form-row gap-3 gap-md-0">
            <Form.Group className="col-md-12" controlId="image1">
              <Form.Label>{t("image")}</Form.Label>
              <div className="w-100 overflow-auto">
                <Controller
                  control={control}
                  name="image1"
                  render={({field}) => (
                    <MediaModal selectedMedia={selectedMedia} setSelectedMedia={setSelectedMedia}
                                checkValue={checkValue} setCheckValue={setCheckValue} allMediaId={allMediaId}>
                      {
                        selectedMedia?.length > 0 ? (
                          <MediaCardList selectedMedia={selectedMedia} isDeleteServer
                                         handleServerRemove={handleServerRemove} />
                        ) : (
                          <div style={{height: "300px", width: "100%"}}>
                            <ImageInput
                              errorMessage={errors.image?.message}
                              onChange={field.onChange}
                              onError={() => {
                                setError("image", {message: "Invalid image file!"});
                              }}
                            />
                          </div>
                        )
                      }
                    </MediaModal>
                  )}
                />
              </div>
            </Form.Group>
          </div>
          <Form.Group controlId="description1">
            <Form.Label>{t("description")} {t("1")}</Form.Label>
            <Controller
              control={control}
              name="description1"
              render={({field}) => <TextEdit defaultValue={component?.description1} {...field} />}
            />
            {errors.description1?.message && (
              <Form.Text className="text-danger">
                {errors.description1.message}
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group controlId="description2">
            <Form.Label>{t("description")} {t("2")}</Form.Label>
            <Controller
              control={control}
              name="description2"
              render={({field}) => <TextEdit defaultValue={component?.description2} {...field} />}
            />
            {errors.description2?.message && (
              <Form.Text className="text-danger">
                {errors.description2.message}
              </Form.Text>
            )}
          </Form.Group>
          <div className="form-row gap-3 md-2 gap-md-0">
            <Form.Group as={Col} md={6} className="flex-grow-1" controlId="link1">
              <Form.Label>{t("link")} {t("1")}</Form.Label>
              <Form.Control {...register("link1")} placeholder={t("link_phld")}
                            type="text" />
              {errors.link1?.message && (
                <Form.Text className="text-danger">{errors.link1.message}</Form.Text>)}
            </Form.Group>
            <Form.Group as={Col} md={6} className="flex-grow-1" controlId="link2">
              <Form.Label>{t("link")} {t("2")}</Form.Label>
              <Form.Control {...register("link2")} placeholder={t("link_phld")}
                            type="text" />
              {errors.link2?.message && (
                <Form.Text className="text-danger">{errors.link2.message}</Form.Text>)}
            </Form.Group>
          </div>
          <div className="form-row gap-3 md-2 gap-md-0">
            <Form.Group as={Col} md={6} className="flex-grow-1" controlId="link3">
              <Form.Label>{t("link")} {t("3")}</Form.Label>
              <Form.Control {...register("link3")} placeholder={t("link_phld")}
                            type="text" />
              {errors.link3?.message && (
                <Form.Text className="text-danger">{errors.link3.message}</Form.Text>)}
            </Form.Group>
            <Form.Group as={Col} md={6} controlId="youTubeID">
              <Form.Label>{t("components_create_youtubeID")}</Form.Label>
              <Form.Control {...register("youTubeID")}
                            placeholder={t("components_create_youtubeID_plhd")} type="text" />
              {errors.youTubeID?.message && (
                <Form.Text className="text-danger">{errors.youTubeID.message}</Form.Text>)}
            </Form.Group>
          </div>
          <div className="row mt-2">
            <div className="d-flex justify-content-end">
              <Button className="px-4 mr-2" type="submit">
                {t("components_edit_update")}
              </Button>
              <Link className="btn btn-danger" to="/components/manage">
                {t("pages_edit_cancel")}
              </Link>
            </div>
          </div>
        </Form>
      </Layout>
    </>
  );
}