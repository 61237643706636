import Layout from "../../components/layouts/layout";
import {useQuery} from "../../hooks/useQuery";
import {changePassword, getProfile} from "./auth";
import {Badge, Breadcrumb, Button, Col, Container, Form, Table} from "react-bootstrap";
import {Input, InputGroup, Modal, Placeholder} from "rsuite";
import {useLang} from "../../hooks";
import React, {useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {InputValidationError} from "../../libs/error";
import {useNotification} from "../../hooks/useNotification";
import {FaEye, FaEyeSlash, FaKey} from "react-icons/fa";


export default function UserProfile() {
  const {t} =  useLang()
  const {data: user, error, loading} = useQuery(getProfile, );
  const [showPasswordEdit, setShowPasswordEdit] = useState(false)


  return <>
  {showPasswordEdit && <ChangePasswordModal open={true} onClose={() => setShowPasswordEdit(false)} />}
    <Layout>
      <Breadcrumb className="d-flex align-items-center">
        <Breadcrumb.Item active>
          {t("profile")}
        </Breadcrumb.Item>
      </Breadcrumb>
      {loading && <Placeholder.Graph active={true} />}
      {
        !loading && user && <div>
          <div className="d-flex gap-2 flex-wrap align-items-center">
            <h1>{user?.name}</h1>
            <Button size={"sm"} className="ml-auto" onClick={() => setShowPasswordEdit(true)}>Change Password</Button>
          </div>
          {user.roles && <div className="mt-3">
            {user.roles?.map(r => <Badge className="m-1">{r?.name}</Badge>)}
          </div>}
          <Table className="mt-4">
            <tr>
              <td>Email</td>
              <td>{user?.name}</td>
            </tr>
            <tr>
              <td>Phone</td>
              <td>{user.phone}</td>
            </tr>
            <tr>
              <td>Gender</td>
              <td>{user.gender}</td>
            </tr>
          </Table>
        </div>
      }
    </Layout>
  </>;
}

export function ChangePasswordModal({open, onClose}) {
  const {t} = useLang()
  const [visible, setVisible] = React.useState(false);
  const [visibleConfirm, setVisibleConfirm] = React.useState(false);
  const {formState: {errors}, setError, handleSubmit, register, control} = useForm({resolver: yupResolver(yup.object({
      password: yup.string().required(),
      confirm_password: yup.string().oneOf([yup.ref("password")], "Password must match").required()
    }))})
  const {notifyError, notifySuccess} = useNotification();

  const handleChangePasswordField = () => {
    setVisible(!visible);
  };

  const handleChangeConfirmPasswordField = () => {
    setVisibleConfirm(!visibleConfirm);
  };
  const onPasswordSubmit = async (data) => {
    try {
      await changePassword(data)
      onClose()
      notifySuccess({
        title: "Password Change Successful!",
      });
    } catch(err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError)
        notifyError({
          title: "Input validation failed",
          message: err.message,
        });
      } else {
        notifyError({
          title: "Something Went Wrong.",
          message: err.message,
        });
      }
    }
  }

  return <Modal open={open} onClose={onClose}>
    <Modal.Header>
      <Modal.Title>{t('change-password')}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Container>
        <Form onSubmit={handleSubmit(onPasswordSubmit)}>
          <div className="d-flex flex-column gap-2">
            <Form.Label className="mark-required">{t("password")}</Form.Label>
            <InputGroup>
              <InputGroup.Addon>
                <FaKey/>
              </InputGroup.Addon>
              <Controller name="password" control={control} render={({field}) =>
                <Input {...field} placeholder=" Enter the Password" type={visible ? 'text' : 'password'} />
              }/>
              <InputGroup.Button onClick={handleChangePasswordField}>
                {visible ? <FaEye/>: <FaEyeSlash/>}
              </InputGroup.Button>
            </InputGroup>
            {errors.password?.message && (
              <Form.Text className="text-danger">
                {errors.password.message}
              </Form.Text>
            )}
            <Form.Label className="mark-required">{t("retype_password")}</Form.Label>
            <InputGroup>
              <InputGroup.Addon>
                <FaKey/>
              </InputGroup.Addon>
              <Controller name="confirm_password" control={control} render={({field}) =>
                <Input {...field} placeholder=" Enter the Password" type={visibleConfirm ? 'text' : 'password'} />
              }/>
              <InputGroup.Button onClick={handleChangeConfirmPasswordField}>
                {visibleConfirm ? <FaEye/>: <FaEyeSlash/>}
              </InputGroup.Button>
            </InputGroup>
            {errors.confirm_password?.message && (
              <Form.Text className="text-danger">
                {errors.confirm_password.message}
              </Form.Text>
            )}
          <div className="d-flex gap-2">
            <Button type="submit" className="ml-auto" variant="primary">
              Change
            </Button>
            <Button onClick={onClose} variant="secondary">
              Cancel
            </Button>
          </div>
          </div>
        </Form>
      </Container>
    </Modal.Body>
  </Modal>
}
