import {Input, InputGroup} from "rsuite";
import {useRef} from "react";
import {Link, useNavigate} from "react-router-dom";
import {Badge, Breadcrumb} from "react-bootstrap";
import {BsPencilSquare} from "react-icons/bs";
import DataTable from "react-data-table-component";
import Layout from "../../components/layouts/layout";
import ComponentGuard from "../../helpers/ComponentGuard";
import {useLang, useUserList} from "../../hooks";
import {FaSearch, FaUserPlus} from "react-icons/fa";
import {FloatingButton} from "../../components";
import {Permission} from "../../configs/constants";
import {useNotification} from "../../hooks/useNotification";

export function UserListPage() {
  const {t} = useLang();
  const {
    userList,
    pagination,
    changePagination,
    isLoading,
    filterDispatch,
    error
  } = useUserList();
  const searchBoxRef = useRef();
  const navigate = useNavigate();
  const {notifyError} = useNotification();

  if (error) {
    notifyError(error, "/error");
  }

  const columns = [
    {
      name: "#",
      cell: (row, index) =>
        (pagination.page - 1) * pagination.limit + (index + 1), //RDT provides index by default
      width: "50px"
    },
    {
      name: t("users_col_name"),
      selector: (row) => row.name,
      sortable: true
    },
    {
      name: t("users_col_role"),
      selector: (row) => (
        <div className="d-flex gap-1 flex-wrap">
          {row.roles.map((r) => <Badge variant="secondary">{r.name}</Badge>)}
        </div>),
      sortable: true
    },
    {
      name: t("users_col_email"),
      width: "250px",
      selector: (row) => row.email,
      sortable: true
    },
    {
      name: t("users_col_phone_number"),
      width: "150px",
      selector: (row) => row.phone,
      sortable: true
    },
    {
      name: t("users_col_gender"),
      selector: (row) => row.gender,
      sortable: true
    },
    {
      name: t("users_col_view"),
      selector: (row) => (
        <Link to={`/user/${row.id}`} className="btn btn-secondary px-3">
          <BsPencilSquare />
        </Link>
      )
    }
  ];

  return (
    <>
      <Layout>
        <header>
          <div className="d-flex justify-content-between">
            <div>
              <Breadcrumb>
                <Breadcrumb.Item active>
                  {t("users_page_title")}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </header>

        <h2>{t("users_page_title_usersList")}</h2>

        <DataTable
          columns={columns}
          data={userList.users}
          progressPending={isLoading}
          pagination
          paginationServer
          paginationComponentOptions={{noRowsPerPage: true}}
          paginationTotalRows={userList.total}
          onChangePage={(page) => {
            changePagination({page});
          }}
          fixedHeader
          fixedHeaderScrollHeight="800px"
          highlightOnHover
          subHeader
          subHeaderComponent={
            <InputGroup className="mb-3" style={{width: "300px"}}>
              <Input ref={searchBoxRef} placeholder={t("users_search")} />
              <InputGroup.Button
                onClick={() => filterDispatch({
                  type: "set",
                  payload: {
                    key: "term",
                    value: searchBoxRef.current.value
                  }
                })}
              >
                <FaSearch />
              </InputGroup.Button>
            </InputGroup>
          }
          subHeaderAlign="right"
        />
      </Layout>
      <ComponentGuard needsPermission={[Permission.UserAdd]}>
        <FloatingButton onClick={() => {
          navigate("/user/create");
        }} icon={<FaUserPlus />} name={t("users_new_user")} />
      </ComponentGuard>
    </>
  );
}

export default UserListPage;
