import {useEffect} from "react";
import {Breadcrumb, Button, Col, Form} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useNotification} from "../../hooks/useNotification";
import {InputValidationError} from "../../libs/error";

import {yupResolver} from "@hookform/resolvers/yup";
import CircleLoader from "../../components/Loaders/CircleLoader";
import Layout from "../../components/layouts/layout";

import {useContent, useLang, useTermList} from "../../hooks";
import {ContentInputSchema} from "./schema.js";
import {ImageInput} from "../../components/MediaInputs/imageInput";
import {BackButton} from "../../components/BackButton/BackButton";
import MediaCardList from "../../components/MediaSelectorModal/MediaCardList";
import MediaModal from "../../components/MediaSelectorModal/MediaModal";
import {useMediaSelector} from "../../components/MediaSelectorModal";
import {TextEdit} from "../../components";
import {SelectPicker, TreePicker} from "rsuite";
import {usePageList} from "../../hooks/usePageList";
import {organizeData} from "../../helpers";

export function ContentEditPage() {
  const {t} = useLang();
  const {id} = useParams();
  const {content, editContent, handleMediaRemove, isLoading, error} = useContent(id);
  const navigate = useNavigate();
  const {notifyError, notifySuccess} = useNotification();
  const {
    termList,
    error: termsError,
    isLoading: isTermsLoading,
  } = useTermList(9, {limit: 1000, page: 1});

  const {
    register,
    handleSubmit,
    control,
    formState: {errors},
    setValue,
    setError,
    watch,
  } = useForm({
    resolver: yupResolver(ContentInputSchema(t)),
  });

  const {
    selectedMedia,
    setSelectedMedia,
    checkValue,
    setCheckValue,
    allMediaId,
    setAllMediaId
  } = useMediaSelector();
  const {
    pageList,
    isLoading: pageLoading,
    error: pageErr
  } = usePageList(null, {page: 1, limit: 100});

  const name = watch("name");

  if (error) {
    notifyError(error);
    setTimeout(() => navigate("/contents/manage"), 500);
  }

  if (termsError) {
    notifyError(termsError);
    navigate("/contents/manage");
  }

  const handleServerRemove = async (media_id) => {
    try {
      await handleMediaRemove(media_id);
      notifySuccess({
        title: `Media Removed successfully`
      });
      setCheckValue(checkValue.filter(m => m!== media_id))
      setSelectedMedia(selectedMedia.filter(m => m.id !== media_id))
      setAllMediaId(allMediaId.filter(m => m!==media_id));
    } catch (err) {
      notifyError({
        title: "Failed to Removed media!",
        message: err.message
      });
    }
  };

  useEffect(() => {
    if (content && termList.terms.length > 0) {
      setValue("title", content?.title ?? "");
      setValue("description", content?.description ?? "");
      setValue("link", content?.link ?? "");
      setValue("terms_id", content?.terms_id ?? "");
      setValue("subtitle", content?.subtitle ?? "");
      setValue("page_id", content?.page_id ?? "");


      let mediaIds = content?.files ? content?.files.map(m => m.media_id) : [];
      let media = content?.files ? content?.files.map(m => m.media_content) : [];

      setSelectedMedia(media);
      setCheckValue(mediaIds);
      setAllMediaId(mediaIds);
    }
  }, [content, termList]);

  const handleEdit = async (data) => {
    try {
      await editContent({...data, media_id: checkValue});
      notifySuccess({
        title: `Content updated successfully`,
      });
      navigate("/contents/manage");
    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Invalid input data!",
          message: err.message,
        });
        return;
      }
      notifyError({
        title: "Failed to update content!",
        message: err.message,
      });
    }
  };

  return (
    <Layout>
      {(isLoading || isTermsLoading) && <CircleLoader/>}
      <header>
        <div className="d-flex justify-content-between align-items-center">
          <Breadcrumb className="d-flex align-items-center">
            <li className="breadcrumb-item">
              <Link to={`/contents/manage`}> {t("contents_contents")}</Link>
            </li>
            <Breadcrumb.Item active>
              {t("contents_edit_edit")}
            </Breadcrumb.Item>
          </Breadcrumb>
          <BackButton path={-1} text={t("contents_contents")}/>
        </div>
      </header>
      <section id="#content-edit">
        <h2>{t("contents_edit_edit")} / {content?.title}</h2>
      </section>
      <Form
        onSubmit={handleSubmit(handleEdit)}
        className="mt-4 d-flex  flex-column gap-3"
      >
        <div className="form-row">
          <Form.Group as={Col} md={4} controlId="terms_id">
            <Form.Label className="mark-required">{t("contents_edit_term")}</Form.Label>
            <Controller render={({field}) => <TreePicker
              loading={isTermsLoading} {...field}
              data={organizeData(termList.terms)}
              block
              searchable={false}
              placeholder="Select Categories"
              defaultExpandAll
            />
            } name="terms_id" control={control}/>
            {errors.terms_id?.message && (
              <Form.Text className="text-danger">
                {errors.terms_id.message}
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group as={Col} className="flex-grow-1" controlId="name">
            <Form.Label className="mark-required">{t("contents_edit_name")}</Form.Label>
            <Form.Control
              {...register("title")}
              placeholder={t("contents_edit_name_plhd")}
              type="text"
            />
            {errors.title?.message && (
              <Form.Text className="text-danger">
                {errors.title.message}
              </Form.Text>
            )}
          </Form.Group>
        </div>
        <Form.Group className="flex-grow-1" controlId="subtitle">
          <Form.Label>{t("contents_edit_subtitle")}</Form.Label>
          <Form.Control
            {...register("subtitle")}
            placeholder={t("contents_edit_subtitle_plhd")}
            type="text"
          />
          {errors.title?.message && (
            <Form.Text className="text-danger">
              {errors.title.message}
            </Form.Text>
          )}
        </Form.Group>
        <Form.Group controlId="description">
          <Form.Label>{t("contents_edit_Description")}</Form.Label>
          <Controller
            control={control}
            name="description"
            render={({field}) => <TextEdit defaultValue={content?.description} {...field} />}
          />
          {errors.description?.message && (
            <Form.Text className="text-danger">
              {errors.description.message}
            </Form.Text>
          )}
        </Form.Group>

        <div className="form-row">
          <Form.Group as={Col} md={12} lg={12} className="mb-4">
            <Form.Label>{t("contents_create_image")}</Form.Label>
            <div className="h-100">
              <Controller
                control={control}
                name="image"
                render={({field}) => (
                  <MediaModal selectedMedia={selectedMedia} setSelectedMedia={setSelectedMedia}
                              checkValue={checkValue} setCheckValue={setCheckValue} allMediaId={allMediaId}>
                    {
                      selectedMedia?.length > 0 ? (
                        <MediaCardList selectedMedia={selectedMedia} isDeleteServer handleServerRemove={handleServerRemove}/>
                      ): (
                        <div style={{height: "300px", width: "100%"}}>
                          <ImageInput
                            errorMessage={errors.image?.message}
                            onChange={field.onChange}
                            onError={() => {
                              setError("image", {message: "Invalid image file!"});
                            }}
                          />
                        </div>
                      )
                    }
                  </MediaModal>
                )}
              />
            </div>
          </Form.Group>
        </div>
        <Form.Group controlId="Link">
          <Form.Label>{t("contents_create_link")}</Form.Label>
          <Form.Control
            {...register("link")}
            placeholder={t("contents_create_link_plhd")}
            type="text"
          />
          {errors.link?.message && (
            <Form.Text className="text-danger">
              {errors.link.message}
            </Form.Text>
          )}
        </Form.Group>
        <Form.Group controlId="page_id">
          <Form.Label>{t("menu_content_page")}</Form.Label>
          <Controller control={control} render={
            ({field}) =>
              <SelectPicker onChange={field.onChange} value={field.value} data={pageList?.pages.map(p => ({
                label: p.title,
                value: p.id
              }))} style={{width: "100%"}} />
          } name="page_id" />

          {errors?.page_id?.message && (
            <Form.Text className="text-danger">{errors.page_id.message}</Form.Text>
          )}
        </Form.Group>

        <div className="d-flex justify-content-end gap-2">
          <Button className="px-4" variant="success" type="submit">
            {t("contents_edit_save")}
          </Button>
          <Link className="btn btn-danger" to="/contents/manage">
            {t("contents_edit_cancel")}
          </Link>
        </div>
      </Form>
    </Layout>
  );
}
