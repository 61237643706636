import {useState} from "react";
import {Breadcrumb, Button, Col, Form} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {Link, useNavigate} from "react-router-dom";
import {useLang, useTermList} from "../../hooks";
import {useNotification} from "../../hooks/useNotification";
import {InputValidationError} from "../../libs/error";

import {yupResolver} from "@hookform/resolvers/yup";
import CircleLoader from "../../components/Loaders/CircleLoader";
import Layout from "../../components/layouts/layout";
import {ContentInputSchema} from "./schema";
import {ImageInput} from "../../components/MediaInputs/imageInput";
import {VideoInput} from "../../components/MediaInputs/videoInput";
import {createContent} from "../../services/content";
import {BackButton} from "../../components/BackButton/BackButton";
import MediaCard from "../MediaManagement/components/MediaCard";
import MediaModal from "../../components/MediaSelectorModal/MediaModal";
import {useMediaSelector} from "../../components/MediaSelectorModal";
import {TextEdit} from "../../components";
import {SelectPicker, TreePicker} from "rsuite";
import {usePageList} from "../../hooks/usePageList";
import {organizeData} from "../../helpers";

export function ContentCreatePage() {
  const {t} = useLang();
  const [isLoading, setIsLoading] = useState();
  const navigate = useNavigate();
  const {notifyError, notifySuccess} = useNotification();
  const {
    register,
    handleSubmit,
    control,
    formState: {errors},
    setError
  } = useForm({
    resolver: yupResolver(ContentInputSchema(t))
  });
  const {
    selectedMedia,
    setSelectedMedia,
    checkValue,
    setCheckValue,
    allMediaId,
    setAllMediaId,
    handleRemove
  } = useMediaSelector();
  const {
    pageList,
    isLoading: pageLoading,
    error: pageErr
  } = usePageList(null, {page: 1, limit: 100});


  const {
    termList,
    error: termsError,
    isLoading: isTermsLoading
  } = useTermList(9, {limit: 1000, page: 1});

  if (termsError) {
    notifyError(termsError);
    navigate("/contents/manage");
  }

  const create = async (data) => {
    try {
      const contentData = {...data, media_id: checkValue}
      setIsLoading(true);
      const content = await createContent(contentData);
      notifySuccess({
        title: "Content created successfully",
        message: `Content Successfully created!`
      });

      navigate("/contents/manage");
    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Input validation failed",
          message: err.message
        });
        return;
      }
      notifyError({
        title: "Something went wrong",
        message: "Content creation failed!"
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Layout>
      {(isTermsLoading || isLoading) && <CircleLoader />}
      <header>
        <div className="d-flex justify-content-between align-items-center">
          <Breadcrumb className="d-flex align-items-center">
            <li className="breadcrumb-item">
              <Link to={`/contents/manage`}> {t("contents_contents")}</Link>
            </li>
            <Breadcrumb.Item active>
              {t("contents_create_createNewContent")}
            </Breadcrumb.Item>
          </Breadcrumb>
          <BackButton path={-1} text={t("contents_contents")} />
        </div>
      </header>
      <section id="#content-create">
        <h2>{t("contents_create_createNewContent")}</h2>
      </section>
      <Form
        onSubmit={handleSubmit(create)}
        className="mt-4 d-flex  flex-column gap-3"
      >
        <div className="form-row">
          <Form.Group as={Col} md={4} controlId="terms_id">
            <Form.Label className="mark-required">
              {t("contents_create_term")}
            </Form.Label>
            <Controller render={({field}) => <TreePicker
              loading={isTermsLoading} {...field}
              data={organizeData(termList.terms)}
              block
              searchable={false}
              placeholder="Select Categories"
              defaultExpandAll
            />
            } name="terms_id" control={control}/>
            {errors.terms_id?.message && (
              <Form.Text className="text-danger">
                {errors.terms_id.message}
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group as={Col} className="flex-grow-1" controlId="name">
            <Form.Label className="mark-required">
              {t("contents_create_name")}
            </Form.Label>
            <Form.Control
              {...register("title")}
              placeholder={t("contents_create_name_plhd")}
              type="text"
            />
            {errors.title?.message && (
              <Form.Text className="text-danger">
                {errors.title.message}
              </Form.Text>
            )}
          </Form.Group>
        </div>
        <Form.Group controlId="subtitle">
          <Form.Label>{t("contents_edit_subtitle")}</Form.Label>
          <Form.Control
            {...register("subtitle")}
            placeholder={t("contents_edit_subtitle_plhd")}
            type="text"
          />
          {errors.subtitle?.message && (
            <Form.Text className="text-danger">
              {errors.subtitle.message}
            </Form.Text>
          )}
        </Form.Group>
        <Form.Group controlId="description">
          <Form.Label>
            {t("contents_create_Description")}
          </Form.Label>
          <Controller
            control={control}
            name="description"
            render={({field}) => <TextEdit {...field} />}
          />
          {errors.description?.message && (
            <Form.Text className="text-danger">
              {errors.description.message}
            </Form.Text>
          )}
        </Form.Group>

        <div className="form-row">
          <Form.Group as={Col} md={12} lg={12} className="mb-4">
            <Form.Label>{t("contents_create_image")}</Form.Label>
            <Controller
              control={control}
              name="image"
              render={({field}) => (
                <MediaModal selectedMedia={selectedMedia} setSelectedMedia={setSelectedMedia}
                            checkValue={checkValue} setCheckValue={setCheckValue} allMediaId={allMediaId}>
                  {
                    selectedMedia?.length > 0 ? (
                      selectedMedia?.map((md) =>
                        <MediaCard key={md.id} media={md} isDelete handleRemove={handleRemove}/>
                      )
                    ): (
                      <div style={{height: "300px", width: "100%"}}>
                        <ImageInput
                          errorMessage={errors.image?.message}
                          onChange={field.onChange}
                          onError={() => {
                            setError("image", {message: "Invalid image file!"});
                          }}
                        />
                      </div>
                    )
                  }
                </MediaModal>
              )}
            />
          </Form.Group>
        </div>
        <Form.Group controlId="Link">
          <Form.Label>
            {t("contents_create_link")}
          </Form.Label>
          <Form.Control
            {...register("link")}
            placeholder={t("contents_create_link_plhd")}
            type="text"
          />
          {errors.link?.message && (
            <Form.Text className="text-danger">{errors.link.message}</Form.Text>
          )}
        </Form.Group>
        <Form.Group controlId="page_id">
          <Form.Label>{t("menu_content_page")}</Form.Label>
          <Controller control={control} render={
            ({field}) =>
              <SelectPicker onChange={field.onChange} value={field.value} data={pageList?.pages.map(p => ({
                label: p.title,
                value: p.id
              }))} style={{width: "100%"}} />
          } name="page_id" />

          {errors?.page_id?.message && (
            <Form.Text className="text-danger">{errors.page_id.message}</Form.Text>
          )}
        </Form.Group>
        <div className="d-flex justify-content-end">
          <Button className="px-4" type="submit">
            {t("contents_create_save")}
          </Button>
        </div>
      </Form>
    </Layout>
  );
}
