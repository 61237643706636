import {useState} from "react";
import {Breadcrumb, Button, Col, Form} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {Link, useNavigate, useParams} from "react-router-dom";
import {SelectPicker, TreePicker} from "rsuite";
import {useLang, useTermList} from "../../hooks";
import {useNotification} from "../../hooks/useNotification";
import {InputValidationError} from "../../libs/error";

import {yupResolver} from "@hookform/resolvers/yup";
import CircleLoader from "../../components/Loaders/CircleLoader";
import Layout from "../../components/layouts/layout";
import {TermInputSchema} from "./termSchema";
import useTaxonomy from "../../hooks/useTaxonomy";
import {createTerm} from "../../services/term";
import {BackButton} from "../../components/BackButton/BackButton";
import {TextEdit} from "../../components";
import MediaModal from "../../components/MediaSelectorModal/MediaModal";
import MediaCard from "../MediaManagement/components/MediaCard";
import {ImageInput} from "../../components/MediaInputs/imageInput";
import {useMediaSelector} from "../../components/MediaSelectorModal";
import {organizeData} from "../../helpers";

export function TermsCreatePage() {
  const {t} = useLang();
  const {id: taxonomyId} = useParams();
  const {termList, isTermLoading} = useTermList(taxonomyId, {limit: 1000, page: 1})
  const {taxonomy, error: taxonomyError, isLoading: isTaxonomyLoading} = useTaxonomy(taxonomyId)
  const [isLoading, setIsLoading] = useState();

  const navigate = useNavigate();

  const {notifyError, notifySuccess} = useNotification();

  if (taxonomyError && !isLoading) {
    notifyError(taxonomyError)
    navigate("/")
  }

  const {
    register,
    handleSubmit,
    control,
    formState: {errors},
    setError,
  } = useForm({
    resolver: yupResolver(TermInputSchema(t)),
  });

  const {
    selectedMedia,
    setSelectedMedia,
    checkValue,
    setCheckValue,
    allMediaId,
    setAllMediaId,
    handleRemove
  } = useMediaSelector();

  const create = async (data) => {
    try {
      setIsLoading(true);
      if (!data.description) {
        delete data.description;
      }
      const t = await createTerm({...data, taxonomy_id: taxonomyId, media_id: checkValue[0]});
      notifySuccess({
        title: "Term created successfully",
        message: `Term "${t.name}" created!`,
      });
      navigate(`/taxonomies/${taxonomyId}/terms`);
    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Input validation failed",
          message: err.message,
        });
        return;
      }
      notifyError({
        title: "Something went wrong",
        message: err.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Layout>
      {(isLoading || isTaxonomyLoading) && <CircleLoader/>}
      <header>
        <div className="d-flex justify-content-between align-items-center">
          <Breadcrumb className="d-flex align-items-center">
            <li className="breadcrumb-item">
              <Link to={`/taxonomies/${taxonomyId}/terms`}> {t("terms_manage_terms")}</Link>
            </li>
            <Breadcrumb.Item active>
              {t("terms_create_createNewTerm")}
            </Breadcrumb.Item>
          </Breadcrumb>
          <BackButton path={-1} text={t("users_page_title")}/>
        </div>
      </header>
      <section id="#term-create">
        <h2>{taxonomy?.name ?? "Taxonomy"}/{t("terms_create_createNewTerm")}</h2>
      </section>
      <Form
        onSubmit={handleSubmit(create)}
        className="mt-4 d-flex  flex-column gap-3"
      >
        <div className="form-row">
          <Form.Group as={Col} md={2} controlId="parent_id">
            <Form.Label>{t("terms_input_term")}</Form.Label>
            <div>
              <Controller render={({field}) => <TreePicker
                loading={isTermLoading} {...field}
                data={organizeData(termList.terms)}
                block
                searchable={false}
                placeholder="Select Categories"
                defaultExpandAll
              />
              } name="parent_id" control={control}/>
            </div>
            {errors.parent_id?.message && (
              <Form.Text className="text-danger">
                {errors.parent_id.message}
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group className="flex-grow-1" controlId="name">
            <Form.Label className="mark-required">{t("terms_input_name")}</Form.Label>
            <Form.Control
              {...register("name")}
              placeholder={t("terms_input_name_p")}
              type="text"
            />
            {errors.name?.message && (
              <Form.Text className="text-danger">
                {errors.name.message}
              </Form.Text>
            )}
          </Form.Group>
        </div>

        <Form.Group controlId="description">
          <Form.Label>{t("terms_input_description")}</Form.Label>
          <Controller
            control={control}
            name="description"
            render={({field}) => <TextEdit {...field} />}
          />
          {errors.description?.message && (
            <Form.Text className="text-danger">
              {errors.description.message}
            </Form.Text>
          )}
        </Form.Group>
        <Form.Group controlId="image">
          <Form.Label>{t("events_input_image")}</Form.Label>
          <div className="w-100">
            <Controller
              control={control}
              name="image"
              render={({field}) => (
                <MediaModal isSingleMode selectedMedia={selectedMedia} setSelectedMedia={setSelectedMedia}
                            checkValue={checkValue} setCheckValue={setCheckValue} allMediaId={allMediaId}>
                  {
                    selectedMedia?.length > 0 ? (
                      selectedMedia?.map((md) =>
                        <MediaCard key={md.id} media={md} isDelete handleRemove={handleRemove}/>
                      )
                    ): (
                      <div style={{height: "300px", width: "100%"}}>
                        <ImageInput
                          errorMessage={errors.image?.message}
                          onChange={field.onChange}
                          onError={() => {
                            setError("image", {message: "Invalid image file!"});
                          }}
                        />
                      </div>
                    )
                  }
                </MediaModal>
              )}
            />
          </div>
        </Form.Group>
        <div className="d-flex justify-content-end gap-2">
          <Button className="px-4" type="submit">
            {t("terms_create_create")}
          </Button>
          <Link to={`/taxonomies/${taxonomyId}/terms`} className="btn btn-danger">
            {t("terms_create_cancel")}
          </Link>
        </div>
      </Form>
    </Layout>
  );
}
