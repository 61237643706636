import {api, parseAPIError} from "./api";
import {jsonToFormData} from "../helpers";
import {processEventInput} from "./event";

export function processComponentInput(input) {
  if(Number(input.type_id) === 12) {
    input.identifier_id = null;
  }
  return input;
}

export async function getComponentCategories() {
  const r = await api.get("/component/categories");
  return r.data?.data?.map(
    item => ({label: item.name, value: item.id})
  ) ?? [];
}

export async function gettComponentIdentifierListByCategoryId(categoryId) {
  const r = await api.get(`/component/identifierList/${categoryId}`);
  return r.data?.data?.map(
    item => ({label: (item.name ? item.name : item.title), value: item.id})
  ) ?? [];
}


export async function createComponent(input) {
  try {
    let data = processEventInput(input);
    data = jsonToFormData(data);
    const r = await api.post("/component/store", data);
    return r.data.data;
  } catch (err) {
    throw parseAPIError(err);
  }
}

export async function createComponentLayout(input) {
  try {
    let data = jsonToFormData(input);
    const r = await api.post("/layout_packs/store", data);
    return r.data.data;
  } catch (err) {
    throw parseAPIError(err);
  }
}

export async function getComponentLayout(id) {
  try {
    const r = await api.get(`/layout_packs/get/${id}`);
    return r.data.data;
  } catch (err) {
    throw parseAPIError(err);
  }
}

export async function updateComponentLayout(input, id) {
  try {
    let data = jsonToFormData(input);
    const r = await api.post(`/layout_packs/update/${id}`, data);
    return r.data.data;
  } catch (err) {
    throw parseAPIError(err);
  }
}


export async function getPaginatedLayout(options) {
  
  const r = await api.get("/layout_packs/show", {
    params: options
  });
  if (r.data.data) {
    return r.data.data;
  }
  return {
    data: [],
    total: 0
  };
  
}

export async function removeLayout(id) {
  debugger;
  const r = await api.delete(`/layout_packs/delete/${id}`);
  return r.data.data;
}

export async function getComponent(id) {
  const r = await api.get(`component/get/${id}`);
  return r.data.data;
}

export async function updateComponent(id, input) {
  try {
    let data = processComponentInput(input);
    data = jsonToFormData(data);
    const r = await api.post(`component/update/${id}`, data);
    return r.data.data;
  } catch (err) {
    throw parseAPIError(err);
  }
}

export async function getPaginatedComponent(options) {
  const r = await api.get("/component/list", {
    params: options
  });
  if (r.data.data) {
    return r.data.data;
  }
  return {
    data: [],
    total: 0
  };
}

export async function removeComponent(id) {
  const r = await api.delete(`component/delete/${id}`);
  return r.data.data;
}

export async function removeComponentMedia(id, data) {
  let processData = jsonToFormData(data)
  const r = await api.post(`/component/delete-media-id/${id}`, processData);
  return r.data;
}

export async function removeComponentLanguageMedia(id, data) {
  let processData = jsonToFormData(data)
  const r = await api.post(`/component-languages/${id}/delete-media`, processData);
  return r.data;
}

