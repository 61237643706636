import React, {useEffect, useState} from "react";
import {FaEye, FaEyeSlash, FaKey, FaUserAlt} from "react-icons/fa";
import {Link, useNavigate} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";
import {Button, Form} from "react-bootstrap";
import http from "../../http";
import Cookies from "universal-cookie";
import styles from "./css/style.css";
import {getUrlFromStoragePath} from "../../configs";
import {Input, InputGroup} from "rsuite";
import {login} from "./auth";
import {InputValidationError} from "../../libs/error";
import {useNotification} from "../../hooks/useNotification";

const cookies = new Cookies();

const Login = () => {
  const navigate = useNavigate();
  const [adminLogo, setAdminLogo] = useState(null);
  const [visible, setVisible] = React.useState(false);

  const {
    handleSubmit,
    control,
    formState: {errors},
    setError,
  } = useForm();
  const {notifyError, notifySuccess} = useNotification();

  const onSubmit = async (data, e) => {
    try {
      debugger;
      const res = await login(data)
      notifySuccess({
        title: "Login successful.",
      });
      res.isSuper = res.roles_names.includes("SuperAdmin");
      localStorage.setItem(
        "userPermissions",
        JSON.stringify(res.permissions)
      );
      delete res.permissions;
      delete res.roles_names;
      delete res.refresh_token;
      delete res.expires_at;
      delete res.user.roles;
      // return
      cookies.set("userAuth", JSON.stringify(res));
      navigate("/dashboard");
    } catch(err) {
      console.log(err);
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Input validation failed",
          message: err.message,
        });
      }
      else if(err?.response?.data?.data?.code === 'AUTHENTICATION_ERROR') {
        notifyError({
          title: "Authentication failed.",
          message: "Invalid username or password. Please try again.",
        });
      } else {
        notifyError({
          title: "Something Went Wrong.",
          message: err.message,
        });
      }
    }
  };
  const onError = (errors, e) => console.log(errors, e);

  const handleChangePasswordField = () => {
    setVisible(!visible);
  };

  useEffect(() => {
    http
      .get("/frontend/menus")
      .then((res) => {
        setAdminLogo(res.data.data[0].images.original_url)
      })
  },[])

  return (
    <>
      <div className="limiter" style={styles}>
        <div
          className="container-login100"
          style={{backgroundImage: "url(\"Group-1241.png\")"}}
        >
          <div className="wrap-login100 pt-4">
            {adminLogo ? (
              <img src={getUrlFromStoragePath(adminLogo)} className="mx-auto d-block img-fluid pt-4 pb-5" alt="Logo" />
            ) : (
              <span className="login100-form-title pt-4 pb-5">CMS Admin Panel</span>
            )}
            <Form
              className="login100-form validate-form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <InputGroup style={{borderRadius: '50px', padding: '10px', background: 'white'}}>
                <InputGroup.Addon>
                  <FaUserAlt/>
                </InputGroup.Addon>
                <Controller name="email" control={control} render={({field}) =>
                  <Input {...field} placeholder="Enter the Email"/>
                }/>
              </InputGroup>
              {errors.email?.message && (
                <Form.Text className="text-danger">
                  {errors.email.message}
                </Form.Text>
              )}
              <InputGroup style={{borderRadius: '50px', padding: '10px', background: 'white'}} className="mt-3">
                <InputGroup.Addon>
                  <FaKey/>
                </InputGroup.Addon>
                <Controller name="password" control={control} render={({field}) =>
                  <Input {...field} placeholder=" Enter the Password" type={visible ? 'text' : 'password'} />
                }/>
                <InputGroup.Button onClick={handleChangePasswordField}>
                  {visible ? <FaEye/>: <FaEyeSlash/>}
                </InputGroup.Button>
              </InputGroup>
              {errors.password?.message && (
                <Form.Text className="text-danger">
                  {errors.password.message}
                </Form.Text>
              )}
              <div className="container-login100-form-btn pt-3">
                <Button
                  id="login100-form-btn"
                  variant="success"
                  type="submit"
                >
                  LOGIN
                </Button>
                <div className="text-center p-3">
                  <Link to="/forgot-password" style={{color: "black"}}>
                    Forgot Password?
                  </Link>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
