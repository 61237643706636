export function arrayToObject(arrayName, array) {
  const obj = {};
  for (let i = 0; i < array.length; i++) {
    obj[`${arrayName}[${i}]`] = array[i];
  }
  return obj;
}

export function jsonToFormData(requestData) {
  const formData = new FormData();
  Object.entries(requestData).forEach(([key, value]) => {
    if (value === undefined || value === null) {
      // Skip undefined and null values
      return;
    }
    if (Array.isArray(value)) {
      // If value is an array, append each element separately
      value.forEach((element, index) => {
        formData.append(`${key}[${index}]`, element);
      });
    } else {
      // Otherwise, append as a single field
      formData.append(key, value);
    }
  });
  return formData;
}

export function organizeDataIntoTree(dataList, parentId = null) {
  const children = dataList
    .filter((item) => item.parent_id === parentId)
    .map((item) => ({
      value: Number(item.id), // Assuming 'id' is unique and can be used as value
      label: item.name,
      // Recursively call organizeDataIntoTree to find children of the current item
      children: organizeDataIntoTree(dataList, item.id)
      // Other properties like 'groupBy' or 'loading' can be added here if needed
    }));

  return children.length > 0 ? children : undefined;
}


export function organizeData(data) {
  // Create a map to store items by their IDs
  const itemMap = new Map();

  // Initialize all objects with the 'value' and 'label' properties and empty 'children'
  data.forEach(item => {
    itemMap.set(item.id, {
      value: item.id,
      label: item.name,
      children: []
    });
  });

  // Assign children to the parent items
  data.forEach(item => {
    const parentId = item.parent_id;
    if (parentId) {
      const parent = itemMap.get(parentId);
      if (parent) {
        parent.children.push(itemMap.get(item.id));
      }
    }
  });

  // Convert empty 'children' arrays to null
  itemMap.forEach(item => {
    if (item.children.length === 0) {
      item.children = null;
    }
  });

  // Extract root items (those without a parent)
  const result = [];
  data.forEach(item => {
    if (!item.parent_id) {
      result.push(itemMap.get(item.id));
    } else if (!itemMap.get(item.parent_id)) {
      // If the parent doesn't exist, also push the item as standalone
      result.push(itemMap.get(item.id));
    }
  });

  return result;
}

